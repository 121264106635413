* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  min-height: 100vh;
}

a {
  text-decoration: none;
}

#root {
  min-height: 100vh;
  display: flex;
}

::-webkit-scrollbar {
  width: 5px; 
}

::-webkit-scrollbar-track {
background-color: transparent;
}

::-webkit-scrollbar-thumb {
background: #c9cacc;
border-radius: 25px;
}

.select-no-legend {
  legend {
    display: none !important;
  }
}

.MuiTypography-h1 {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 32px !important;
}

.MuiTypography-h2 {
  font-size: 20px !important;
  line-height: 28px !important;
}

.MuiTypography-h3 {
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.MuiTypography-h4 {
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 22px !important;
}

.MuiTypography-h5 {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 18px !important;
}

.MuiTypography-subtitle1 {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 26px !important;
}

.MuiTypography-subtitle2 {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.MuiTypography-body1 {
  font-size: 14px !important;
  line-height: 20px !important;
}

.MuiTypography-body2 {
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 18px !important;
}

.MuiTypography-caption {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
}

.MuiFormControlLabel-label.Mui-disabled {
  color: #A5B2C5 !important;
}

.MuiInputLabel-outlined.Mui-focused, .MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: #A5B2C5 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #1F1F1F !important;
  padding: 16px;
}

.MuiOutlinedInput-input {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #1F1F1F !important;
  padding: 16px;
}

.no-header {
  padding-top: 0 !important;

  header {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  // Need to fix grid on mobile
  html, body {
    width: auto!important;
    overflow-x: hidden!important
  }
}

// Video-react override

html .video-react {
  .video-react-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.video-react-fluid,
  &.video-react-16-9,
  &.video-react-4-3 {
    height: 100%;
    background-color: rgba(0,0,0,.46);
  }
}

// Braintree payments override
.braintree-heading {
  font-family: "Nunito Sans";
  font-weight: 600;
  color: #464854
}


.MuiOutlinedInput-notchedOutline {
    border-color: #E5E7EF !important;
  }

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
}

.MuiFormLabel-root.Mui-error {
 color: #596880 !important;
}

.MuiInputBase-input.Mui-disabled {
  color: #CCD0DE !important;
}

.MuiFormLabel-root.Mui-disabled {
  color: #A5B2C5 !important
}

